import {Vue} from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { createStore } from 'vuex'

import dayprogram from './modules/dayprogram'
import socket from './modules/socket'
import clock from './modules/clock'
import user from './modules/user'
import userdata from './modules/userdata'
import bill from './modules/bill'
import hardware from './modules/hardware'
import screen from './modules/screen'
import handtekening from './modules/handtekening'

import * as status from './userdata-status-types'

// Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state: {
    groups:[],
    activities: [],

    // popup
    popupmessages: {},
    popupspecs:{},

    // header
    headerContent: {
      caption:"",
      hint:""
    },
    showHeader: true,
    showFooter: true,
    showActivityCompletePopup: false,
    showMasterIsActivePopup: false,
    errorMessage: '',
    lastLoginMaster: 0,
        
    reloading: false
  },
  modules: {
    dayprogram,
    socket,
    clock,
    user,
    userdata,
    bill,
    hardware,
    screen,
    handtekening
  },
  getters :{

  },
  actions: {
    initApp ({commit, dispatch}) {
      axios
      .get("static/content/teksten/contentApp.json")
      .then(response => {
        commit('screen/SET_COMPONENTS', response.data.components);
        commit('SET_POPUPDATA', response.data.popup);
        commit("SET_ACTIVITIES", response.data.activities);
        commit("screen/SHOW_SCREEN", response.data.startwith);
      })
      dispatch("clock/startClock");
    },
    setHeaderVisibility({commit},bool) {  
      commit('SET_HEADER_VISIBILITY', bool);
    },
    setFooterVisibility({commit},bool) {  
      commit('SET_FOOTER_VISIBILITY', bool);
    },
    setHeaderContent({commit},content) {  
      commit('SET_HEADER_CONTENT', content);
      commit('SET_FOOTER_VISIBILITY', true);
      commit('SET_HEADER_VISIBILITY', true);
    },   
    reloadAppState({commit,state,dispatch}) {
      console.log("reload app state")

      if (state.user.roomname != '') {

        commit('SET_RELOADING', true);
        return new Promise((resolve,reject) => {

          let endpoint = process.env.VUE_APP_API + state.user.roomname + '/app-state'
          axios
            .get(endpoint)
            .then(r => r.data)
            .then(result => {
                if (result.state) {
    
                  console.log(result.state)
                  //commit('program/SET_PROGRAM_ITEM', result.state.assignment );
                  commit('screen/DIM_SCREEN', result.state.dimmed );
    
                  if (!state.user.isMaster) {
                    // see if we are currently in a rode draad activity
                    if (result.state.action === "startItem") {
                      // 
                      console.log("START from appstate:" + result.state.assignment )
                      dispatch("dayprogram/loadDayProgram",null);
                      dispatch("dayprogram/startItemFromSocket",result.state.assignment);
        
                    }
                    // BVB disable this to also stop the assignment when reloading the appstate
                    if (result.state.action === "stopItem") {
                      console.log("STOP from appstate:" + result.state.assignment )
                      dispatch("dayprogram/loadDayProgram",null);
                      dispatch("dayprogram/stopItemFromSocket",result.state.assignment);        
                    }
                    if (result.state.action === "reloadProgram") {
                      // 
                      console.log("reloadProgram from appstate:" + result.state.assignment )
                      dispatch("dayprogram/loadDayProgram",null);      
                    }                  
                  }
                }
                commit('SET_RELOADING', false);
                resolve();
            })
            .catch(error => {
              // no program state present, no problem, there was no activity
              //console.log("could not get state");

              //console.log(error);
              //reject("geen-verbinding-server");
              commit('SET_RELOADING', false);

            });
        });
      }
    }, 
    loadAppState({commit,state,dispatch}) {
      console.log("load app state")

      let endpoint = process.env.VUE_APP_API + state.user.roomname + '/app-state'
      axios
        .get(endpoint)
        .then(r => r.data)
        .then(result => {
            if (result.state) {

              console.log(result.state)
              //commit('program/SET_PROGRAM_ITEM', result.state.assignment );
              commit('screen/DIM_SCREEN', result.state.dimmed );


              // check for login of begeleider
              if (state.user.isMaster) {
                //console.log(result.createdAt);
//                console.log(result.updatedAt);

                let createDate = new Date(result.createdAt);
                let updateDate = new Date(result.updatedAt)

                console.log(createDate)
                console.log(updateDate)

                let now = new Date();
                let distance = now - createDate;
                let minutes = Math.floor((distance/1000)/60);
                //let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                //let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                let msg =  "Minder dan een minuut ";
                if (minutes > 0) {
                  msg = minutes + " minuten "
                }
                commit('SET_LAST_LOGIN_MASTER', msg)

                //if (minutes < 5) {
                  // session is less than 5 minutes old, warn for other active master
                  commit('SHOW_MASTER_IS_ACTIVE_POPUP',true);
                //}
                // console.log("m:" + minutes);
                // console.log("s:" + seconds);
              }
            }
            // else {
            //   commit('program/SET_PROGRAM_ITEM', 0);                  
            // }
        })
        .catch(error => {
          // no program state present, no problem, there was no activity
          //console.log(error);
        })
    }, 
    getAllGroups ({commit, state}) {
     // console.log("get all groups")
      return new Promise((resolve,reject) => {
        let endpoint = process.env.VUE_APP_API + state.user.roomname + "/groups"

        axios
          .get(endpoint)
          .then(r => r.data)
          .then(result => {
             // console.log("save groups")
              commit('REGISTER_GROUPS', result);
              resolve();
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
              reject(error.response.data.message);
          }
          else if (error.status) {
              reject(error.status);
          }
          else {
              reject("geen verbinding met de server");
          }
        })
      });
    },    
    activityTimesUp({commit,state,dispatch}) {

      if (!state.user.isMaster) {
        // students get a popup when time was up but they are still in the activity
        if (state.userdata.currentActivityData && 
            state.userdata.currentActivityData.status == status.PROGRESS)
        {
          commit('SHOW_ACTIVITY_COMPLETE_POPUP',true);
        }
        else {
          dispatch('screen/returnToProgram');
        }
      }
      else {
        // end the activity
        dispatch('dayprogram/stopCurrentItem');
      }
    },  
    clearActivityTimesUp({commit}){
      commit('SHOW_ACTIVITY_COMPLETE_POPUP',false);
    },
    clearMasterActive({commit}){
      commit('SHOW_MASTER_IS_ACTIVE_POPUP',false,0);
    },
    // setErrorMessage({commit},msg) {
    //   commit('SET_ERROR_MESSAGE',msg);
    // },
    // clearErrorMessage({commit}) {
    //   commit('SET_ERROR_MESSAGE','');
    // },
    reset({commit}) {
      commit('RESET');
    },
    closeSession({dispatch},who) {
      console.log("--close session--")
      if (who === 'all') {
        let msg = {
          action: "closeSession"
        }
        console.log("send to students");
        dispatch("socket/sendToStudents",msg);
      }

      dispatch('reset');
      dispatch('socket/reset');      
      dispatch('socket/closeSocket');
      dispatch("user/reset");
      dispatch("userdata/reset");
      dispatch("clock/clearTimer");
      dispatch("screen/showScreen","")
      dispatch("screen/showScreen","setup")
    },
  },
  mutations: {
    RESET(state) {
      //state.activities = [];
      state.groups = [];
      state.lastLoginMaster = 0;
    },
    SET_RELOADING(state, bool) {
      state.reloading = bool;
    },
    SET_ACTIVITIES(state, activities) {
      state.activities = activities;
    },
    SET_POPUPDATA(state, data) {
      state.popupmessages = data.messages;
      state.popupspecs = data.specs;
    },
    SET_HEADER_CONTENT (state, content) {
      state.headerContent = content;
    },
    SET_FOOTER_VISIBILITY (state, bool) {
        state.showFooter = bool;
    },
    SET_HEADER_VISIBILITY (state, bool) {
        state.showHeader = bool;
    },
    SHOW_ACTIVITY_COMPLETE_POPUP (state, bool) {
        state.showActivityCompletePopup = bool;
    },
    SET_LAST_LOGIN_MASTER (state, msg) {
      state.lastLoginMaster = msg;
    },
    SET_ERROR_MESSAGE(state,msg) {
      state.errorMessage = msg;
    },
    SHOW_MASTER_IS_ACTIVE_POPUP (state, bool) {
      state.showMasterIsActivePopup = bool;
    },
    REGISTER_GROUPS (state, groups) {
        state.groups = groups;
    },
  },
  strict: debug
})
